import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Alert from "@material-ui/lab/Alert";
import "typeface-roboto";

const baseUrl = 'https://7t5djbln5h.execute-api.eu-west-1.amazonaws.com/production';

class App extends React.Component {
  state = {
    key: "",
    status: "published",
    report: "",
    loading: false,
    basename: "",
    interval: false,
    error: '',
  };

  handleChange = name => event => {
    if(this.state.interval){
      clearInterval(this.state.interval);
    }

    this.setState({
      [name]: event.target.value,
      report: "",
      loading: false,
      interval: false,
      error: "",
    });
  };

  handleSubmit = async event => {
    event.preventDefault();

    const { key } = this.state;
    if (!key) return;

    this.setState({
      report: "",
      reportName: `Report-${this.state.status}-positions.xlsx`,
      loading: true,
      error: "",
    });

    let response;
    try{
      response = await fetch(`${baseUrl}/report`, {
        method: "POST",
        headers: {
          Authorization: key,
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
        body: JSON.stringify({
          status: this.state.status,
          reportKey: (new URLSearchParams(window.location.search)).get('format') || '7261-6223',
        })
      });

      const {
        status,
        basename,
        error,
      } = await response.json();

      if(status !== 'ok') throw new Error(error);

      this.setState({
        basename,
        interval: setInterval(this.pollReport, 5000),
      });
    } catch (e) {
      this.setState({
        loading: false,
        error: e.error || e.message || 'An unexpected error occured',
      });
    }
  };

  pollReport = async () => {
    let body;
    let response;
    try{
      response = await fetch(`${baseUrl}/download`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/octet-stream",
        },
        body: JSON.stringify({
          basename: this.state.basename,
        })
      });

      if(response.status === 200){
        // Report returned

        // Fix for bad response encoding
        // We convert the response to base64, and then convert it back to a binary blob
        body = btoa(await response.text()); // Convert to base64
        body = await (await fetch(`data:application/octet-stream;base64,${body}`)).blob(); // Convert to binary blob

        clearInterval(this.state.interval);

        if(body){
          let blob = window.URL.createObjectURL(body);

          this.setState({
            report: blob,
            loading: false,
            interval: false,
          });
        }
      }else{
        // Error or not ready
        body = await response.json();
        if(body.status === 'not_ready'){
          return true;
        }else{
          throw body;
        }
      }
    } catch (e) {
      clearInterval(this.state.interval);
      console.log(e);
      this.setState({
        loading: false,
        error: e.error || e.message || 'An unexpected error occured',
      });
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <Paper className={classes.paper}>
          <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
            <Typography component="h1" variant="h2" gutterBottom>
              Generate report
            </Typography>
            {this.state.error && <Alert severity="error">{ this.state.error }</Alert>}
            <TextField
              label="API-key"
              margin="normal"
              className={classes.textField}
              value={this.state.key}
              onChange={this.handleChange("key")}
            />
            <br />
            <Select
              className={classes.textField}
              value={this.state.status}
              onChange={this.handleChange("status")}
              inputProps={{
                name: 'age',
                id: 'age-simple',
              }}
            >
              <MenuItem value="published" selected>Open positions</MenuItem>
              <MenuItem value="unlisted">Unlisted positions</MenuItem>
              <MenuItem value="archived">Archived positions</MenuItem>
            </Select>
            <br />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              margin="normal"
              className={classes.button}
              disabled={this.state.loading}
            >
              Generate
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={!this.state.report}
              href={this.state.report}
              margin="normal"
              download={this.state.reportName}
            >
              Download
            </Button>
            {this.state.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </form>
        </Paper>
      </div>
    );
  }
}

const styles = theme => ({
  button: {
    margin: theme.spacing.unit
  },
  container: {
    display: "flex",
    justifyContent: "center"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 400
  },
  paper: {
    width: 500,
    display: "flex",
    justifyContent: "center",
    margin: 50,
    padding: 25
  }
});

export default withStyles(styles)(App);
